<script>
import DropZone from "@/components/widgets/dropZone";
import readXlsxFile from "read-excel-file";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: 'CreateSmgsButton',
  emits: ['onSuccess'],
  data() {
    return {
      showUploadFormModal: false,
      showExcelPreviewModal: false,
      dropzone_files: [],
      train_name: '',
      is_uploading: false,
      excelRows: []
    }
  },
  methods: {
    isValidExcel(rows) {
      let conditionsToBeValid = [
        null,
        "НОМЕР SMGS",
        "СТАНЦИЯ ОТПРАВЛЕНИЯ",
        "ЗАЯВЛЕНИЯ ОТПРАВИТЕЛЯ",
        "СТАНЦИЯ НАЗНАЧЕНИЯ",
        "ПОГРАНИЧНЫЕ СТАНЦИИ ПЕРЕХОДОВ",
        "ВАГОН",
        "КОНТЕЙНЕР СОБСТВЕННОСТИ",
        "ПОГРУЖЕНО",
        "СПОСОБ ОПРЕДЕЛЕНИЯ МАССЫ",
        "ПЕРЕВОЗЧИКИ(УЧАСТКИ)",
        "КОДЫ ПО УТИ",
        "КОДЫ ПО КЗХ",
        "КОДЫ ПО КЖД",
        "Информация. не предназначенная для перевозчика. № договора  по поставку",
        "ОТПРАВИТЕЛЬ",
        "ПОЛУЧАТЕЛЬ",
        "НАИМЕНОВАНИЕ ГРУЗА",
        "НОМЕР КОНТЕЙНЕРА",
        "ТИП КНТР",
        "ТИП G1",
        "ТАРА КОНТЕЙНЕРА",
        "РОД УПАКОВКИ",
        "К-ВО МЕСТ",
        "НЕТТО",
        "ТАРА",
        "БРУТТО",
        "К-ВО",
        "ЗНАКИ",
        "Документы. приложенные отправителем",
        "ТЖ ПЛОМБА",
        "ИМЯ ИНСПЕКТОРА",
        "Дата"
      ]
      let filtered = Array.from(rows).filter(item => conditionsToBeValid.includes(item) === true);
      let isValid = filtered.length === conditionsToBeValid.length
      return isValid
    },

    async processUploadedExcelFile(file) {
      try {
        if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
          let rows = await readXlsxFile(file)
          if (this.isValidExcel(rows[0])) {
            this.excelRows = rows
            this.dropzone_files.length = 0
            this.dropzone_files.push(file);
            return true
          } else {
            await Swal.fire({
              position: "center",
              icon: "warning",
              title: "Invalid Excel Data",
              showConfirmButton: false,
              timer: 3000,
            });
            return false
          }
        } else {
          await Swal.fire({
            position: "center",
            icon: "warning",
            title: "Form Accepts Only Excel File",
            showConfirmButton: false,
            timer: 3000,
          });
          return false
        }
      } catch {
        await Swal.fire({
          position: "center",
          icon: "warning",
          title: "Invalid Excel Data",
          showConfirmButton: false,
          timer: 3000,
        });
        return false
      }
    },

    async onDropZoneChange() {
      let file = document.querySelector(".dropzoneFile").files[0]
      await this.processUploadedExcelFile(file)
    },

    async onFileDrop(e) {
      let file = e.dataTransfer.files[0];
      await this.processUploadedExcelFile(file)
    },

    async createTrain() {
      await this.submitTrainSmgs()
    },

    closeFormModalAndOpenPreviewModal() {
      this.showUploadFormModal = false
      setTimeout(() => {
        this.showExcelPreviewModal = true
      }, 300)
    },

    closePreviewModalAndOpenFormModal() {
      this.showExcelPreviewModal = false
      setTimeout(() => {
        this.showUploadFormModal = true
      }, 300)
    },

    async submitTrainSmgs() {
      try {
        let formData = new FormData()

        formData.set('excel_file', this.dropzone_files[0])
        formData.set('train_name', this.train_name)
        this.is_uploading = true
        let response = await axios.post('/smgs_print/create_from_excel/', formData)
        this.is_uploading = false
        this.showUploadFormModal = false
        this.showExcelPreviewModal = false
        this.train_name = ''
        this.dropzone_files = []

        await Swal.fire({
          icon: 'success',
          title: 'Finished ✅',
          text: response.data.message ? response.data.message : 'Successfully uploaded the excel file'
        }).then(() => this.$emit('onSuccess'))
      } catch {
        this.is_uploading = false
        alert("error")
      }
    },
  },
  components: {
    DropZone
  },

}
</script>
<template>

  <b-button @click="showUploadFormModal = true" variant="success">
    <i class="ri-add-line align-bottom me-1"></i> Create Train
  </b-button>

  <b-modal v-model="showUploadFormModal" hide-footer hide-header centered>
    <div class="mb-3">
      <label class="form-label mt-0 pt-0">
        Train Name <span class="text-danger">*</span>
      </label>
      <input type="text" class="form-control" v-model="train_name">
    </div>
    <div>

      <DropZone @change="onDropZoneChange" @drop="onFileDrop"/>

      <div v-for="(file, index) of dropzone_files" :key="`file_${index}`"
           class="d-flex justify-content-between border rounded-3 p-2 mt-3">
        <div>
          <h6 class="mb-1">{{ file.name }}</h6>
          <small class="text-muted">Size: {{ (file.size / (1024 * 1024)).toFixed(2) }} MB</small>
        </div>
        <div>
          <button data-dz-remove="" class="btn btn-sm btn-danger"
                  @click="dropzone_files = []">
            Delete
          </button>
        </div>
      </div>

      <b-button variant="success" class="w-100 mt-3"
                @click="closeFormModalAndOpenPreviewModal"
                :disabled="this.train_name.trim().length === 0 || this.dropzone_files.length === 0"
      >
        Next
        <i class="mdi mdi-arrow-right"></i>
      </b-button>

      <button class="d-none" data-bs-dismiss="modal" aria-hidden="true" id="closeExcelUploadwModal"></button>
    </div>
  </b-modal>

  <b-modal v-model="showExcelPreviewModal" :title="`Preview: ${train_name}`" hide-footer centered
           size="xl"
  >
    <div class="table-responsive">
      <table class="table table-sm table-nowrap">
        <thead>
        <tr>
          <template v-for="(row, index) in excelRows[0]" :key="`excel_header_${index}`">
            <th scope="col"
                v-b-tooltip.hover :title="row ? row : '-'"
                class="text-center">{{ row ? row[0] : '' }}
            </th>
          </template>
        </tr>
        </thead>
        <tbody>
        <template v-for="(row, index) in excelRows.slice(1, excelRows.length)" :key="`excel_row_${index}`">
          <tr>
            <template v-for="(td, td_index) in row" :key="`excel_row_data_${td_index}`">
              <th v-b-tooltip.hover :title="td ? td : '-'"
                  v-if="td_index === 0" scope="row" class="text-center">
                <span v-if="td">{{ td }}</span>
                <span v-else class="badge bg-soft-danger text-danger">.</span>
              </th>
              <td v-b-tooltip.hover :title="td ? td : '-'"
                  v-else class="px-0 text-center">
                <span v-if="td" class="badge bg-soft-success text-success">.</span>
                <span v-else class="badge bg-soft-danger text-danger">.</span>
              </td>
            </template>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

    <div class="mt-2 d-flex align-items-center gap-3">
      <b-button variant="light" @click="closePreviewModalAndOpenFormModal()" :disabled="is_uploading">
        <i class="mdi mdi-arrow-left"></i>
        Back
      </b-button>
      <b-button variant="success" @click="createTrain()" :disabled="is_uploading">
        <b-spinner v-if="is_uploading" style="height: 15px; width: 15px"></b-spinner>
        {{ is_uploading ? 'Uploading...' : 'Start Upload' }}
      </b-button>
    </div>
  </b-modal>

</template>